<template>
  <div>
    <div>
      <h1 class="text-center top-text ">Contact Us</h1>
    </div>
    <v-row :class="$vuetify.breakpoint.smAndDown ? 'px-6 py-8' : 'px-16 py-16'">
      <v-col cols="12" md="5">
        <h2 class="mb-16 mt-6">
          Do you have an enquiry?
        </h2>
        <p class="mb-10">
          <span>Address:</span> Plot 2, Ikosi Road, Oregun, Lagos, Nigeria.
        </p>
        <p class="mb-10"><span>Phone Number:</span> (234)814-298-1261</p>
        <p class="mb-10"><span>Email:</span> info@gricd.com</p>
      </v-col>
      <v-col cols="12" md="7">
        <h4 class="mb-6">Leave a Reply</h4>
        <p class="reply mb-6">
          Your email address will not be published.
        </p>

        <v-form ref="form" v-model="valid">
          <div class="name">
            <v-text-field
              v-model="name"
              color="#28a84a"
              outlined
              :rules="nameRules"
              label="Full Name"
              required
              class="text-field"
              validate-on-blur
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              outlined
              color="#28a84a"
              label="E-mail"
              required
            ></v-text-field>
          </div>
          <vue-tel-input-vuetify
            @input="onInput"
            v-model="phone.number"
            outlined
            :error-messages="phoneError"
            color="#28a84a"
            placeholder="Phone Number"
            @keydown="isNumber($event)"
            :rules="phoneRules"
            required
          />

          <v-select
            v-model="select"
            :items="items"
            color="#28a84a"
            outlined
            :rules="[(v) => !!v || 'Industry is required']"
            label="Industry"
            required
            item-color="green"
            item-text="green"
          >
          </v-select>

          <v-text-field
            v-model="subject"
            :rules="subjectRules"
            outlined
            color="#28a84a"
            label="Subject"
            required
          ></v-text-field>

          <v-textarea
            v-model="comment"
            outlined
            color="#28a84a"
            name="input-7-4"
            label="Comment"
            clearable
          ></v-textarea>

          <v-btn
            style="font-size:12px"
            @click="validate"
            color="#28a84a"
            class="white--text mt-6"
            :loading="loading"
            >Send message</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
    <GoogleMap />
  </div>
</template>

<script>
import Vue from 'vue';
import GoogleMap from '../../components/GoogleMap';
import axios from 'axios';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueToast, {
  position: 'top-right',
  duration: 3000,
  dismissible: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places',
  },
});

export default {
  components: {
    GoogleMap,
    VueTelInputVuetify,
  },
  data: () => ({
    valid: false,
    phoneRules: [(v) => !!v || 'Phone is required'],
    myPhone: '',
    phone: {
      number: '',
      valid: false,
      country: undefined,
    },
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      //   (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: ['Agriculture', 'Logistics', 'Health', 'Other sector'],
    subject: '',
    subjectRules: [(v) => !!v || 'Subject is required'],
    comment: '',
    commentRules: [(v) => !!v || 'Comment is required'],
    info: '',
    phoneError: '',
    loading: false,
  }),
  methods: {
    validate() {
      const isVal = this.$refs.form.validate();
      const data = {
        fullname: this.name,
        phone: this.phone.number,
        email: this.email,
        message: this.comment,
        company: this.select,
        subject: this.subject,
      };
      if (this.comment === '') delete data.message;
      if (!isVal) return;
      else this.loading = true;
      axios
        .post('https://stage-gricd.herokuapp.com/v2/admin/contact', data)
        .then((response) => {
          //   this.info = response.data.message;
          this.$toast.success(response.data.message);
          this.reset();
        })
        .catch((error) => {
          this.errored = true;
          this.loading = false;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInput(formattedNumber, { number, valid }) {
      if (number && number.input !== '') {
        if (valid) {
          this.phone.number = number.international;
          this.phoneError = [];
          this.phone.number = number.international;
        } else {
          this.phoneError = ['Invalid number'];
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    name: function(val) {
      if (val === '') this.nameRules = [];
    },
  },
  mounted() {
    // console.log(process.env.GOOGLE_API_KEY);
  },
};
</script>

<style lang="scss" scoped>
.top-text {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
p {
  font-size: 1rem;
}
.button {
  font-size: 12px;
  background: #28a84a;
}
.v-text-field {
  border-radius: 3px;
}
// .v-text-field--outlined ::v-deep fieldset {
//   border-color: rgba(99, 99, 99, 0.3);
// }
span {
  color: #28a84a;
  font-weight: 500;
}
.reply {
  font-weight: 0.9rem;
}
// p.v-toast__text {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
//     Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     font-weight: 800;
//     font-size: 53px !important;
// }
.v-toast ::v-deep .v-toast__item {
  font-family: 'Roboto-Regular' !important ;
  color: white !important;
}
.name {
  column-gap: 2rem;
  display: flex;
  @media (max-width: 599px) {
    // column-gap: 1rem;
    display: block;
  }
}
</style>
