<template>
  <div>
    <!-- <div>
      <h2>Search and add a pin</h2>
      <GmapAutocomplete @place_changed="setPlace" />
      <v-btn @click="addMarker">
        Add
      </v-btn>
    </div>
    <br /> -->
    <GmapMap :center="center" :zoom="12" style="width:100%;  height: 400px;">
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  data() {
    return {
      center: {lat: 6.600996512882168, lng: 3.373945943418193},
      currentPlace: null,
      markers: [],
      places: [],
    };
  },
  mounted() {
    //   this.geolocate();
  },
  methods: {
    //   geolocate: function() {
    //     navigator.geolocation.getCurrentPosition(position => {
    //       this.center = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //     });
    //   },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      //   if (this.currentPlace) {
      const marker = {
        lat: ' 6.600996512882168',
        lng: '3.373945943418193',
      };
      this.markers.push({position: marker});
      this.places.push(this.currentPlace);
      this.center = marker;
      this.currentPlace = null;
      //   }
    },
  },
};
</script>

<style></style>
